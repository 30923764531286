<template>
  <el-dialog :visible.sync="dialog" title="选择关联零售单" append-to-body :close-on-click-modal="false" @closed="handleClosed" width="640px">
    <slot v-bind:target="target"></slot>
    <el-table ref="table" v-loading="loading" row-key="id" :data="data" border max-height="480px" @selection-change="handleSelectionChange">
      <el-table-column type="selection" reserve-selection width="35" />
      <el-table-column prop="formCode" label="零售单号" width="120" />
      <el-table-column prop="shopName" label="销售门店" min-width="200" />
      <el-table-column prop="customerName" label="客户姓名" width="100" />
      <el-table-column prop="quantity" label="销售数量" width="100" align="right" />
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="dialog = false">取消</el-button>
      <el-button type="primary" @click="doSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import initData from "@/mixins/initData";

export default {
  mixins: [initData],
  props: {
    field: {
      type: String,
      default: "goodsId",
    },
  },
  data() {
    return {
      dialog: false,
      target: null,
      selections: [],
    };
  },
  methods: {
    beforeInit() {
      if (this.target && this.target[this.field]) {
        this.url =
          "api/shop/orderFormFull?sort=formCode,desc&statusList=pass&statusList=sendPart&statusList=closedPart&goodsId=" +
          this.target[this.field];
        return true;
      }
      return false;
    },
    afterLoad() {
      this.$nextTick(() => {
        if (this.$refs.table) {
          this.selections.forEach((s) => {
            let row = this.data.find((d) => d.id === s.id);
            if (row) {
              this.$refs.table.toggleRowSelection(row, true);
            }
          });
        }
      });
    },
    set(target) {
      this.target = target;
      this.dialog = true;
      this.init();
      let sels = [];
      this.target.source.forEach((s, i) => {
        sels.push({
          id: s,
          formCode: this.target.retailFormCode[i],
          shopName: this.target.shop[i],
          customerName: this.target.customer[i],
        });
      });
      this.selections = sels;
    },
    doSubmit() {
      this.target.source = this.selections.map((s) => s.id);
      this.target.retailFormCode = this.selections.map((s) => s.formCode);
      this.target.shop = this.selections.map((s) => s.shopName);
      this.target.customer = this.selections.map((s) => s.customerName);
      this.target.retailContractNo = this.selections.map(s=>s.contractNo);
      this.dialog = false;
    },
    handleClosed() {
      this.$refs.table && this.$refs.table.clearSelection();
      this.target = null;
    },
    handleSelectionChange(sels) {
      this.selections = sels;
    },
  },
};
</script>